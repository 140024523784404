import { DialogActions, DialogTitle, useTheme } from "@mui/material";
import {
  DOCUMENT_PRIVACY_POLICY,
  DOCUMENT_TERMS_AND_CONDITIONS,
} from "@recare/core/consts";
import { getApolloNetworkErrorMessage } from "@recare/core/model/utils/errors";
import {
  SimpleFormRenderProps,
  composeValidation,
  convertModelDefinition,
  validateModel,
  valueDef,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { useCreateProviderSearchAccount } from "apollo/hooks/mutations";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import CheckboxInputField from "ds/components/CheckboxInputField";
import RSButton from "ds/components/RSButton";
import TextInputField from "ds/components/TextInputField";
import { HorizontalDivider, VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding } from "ds/materials/metrics";
import { Caption, FONT_SIZE_12 } from "ds/materials/typography";
import { useLegalDocuments } from "dsl/atoms/LegalDocuments";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { useState } from "react";
import { getDialogProps } from ".";
import {
  LoginErrorType,
  LoginErrors,
  SIGNUP_ERROR_TYPE_ALREADY_EXISTS,
} from "./Error";
import { GoToModal } from "./GoToModule";
import {
  CaptionWithLink,
  DialogContentWrapper,
  PROVIDER_SEARCH_LOGIN_MODAL,
  PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS,
  ProviderRegistrationInfo,
  ProviderSearchDialog,
  providerSearchEmailDef,
} from "./shared";

type FormInput = {
  confirm_email: string;
  email: string;
  privacy_policy: boolean;
  terms_and_conditions: boolean;
};

const defaultValue: FormInput = {
  email: "",
  confirm_email: "",
  privacy_policy: false,
  terms_and_conditions: false,
};

const emailsMatch = ({
  confirm_email,
  email,
}: {
  confirm_email: string;
  email: string;
}) => {
  const sanitizedEmail = email.toLowerCase().trim();
  const sanitizedConfirmEmail = confirm_email.toLowerCase().trim();
  if (!sanitizedEmail || !sanitizedConfirmEmail) {
    return true;
  }

  if (sanitizedEmail !== sanitizedConfirmEmail) {
    return false;
  }

  return true;
};

const modelDefinition = convertModelDefinition({
  ...providerSearchEmailDef("email"),
  ...providerSearchEmailDef("confirm_email"),
  ...valueDef("terms_and_conditions", {
    validate(value, { translations }) {
      return value
        ? true
        : {
            customMessage:
              translations.providersearch.loginModal.createAccount
                .acceptTermsError,
          };
    },
  }),
  ...valueDef("privacy_policy", {
    validate(value, { translations }) {
      return value
        ? true
        : {
            customMessage:
              translations.providersearch.loginModal.createAccount
                .acceptDPAError,
          };
    },
  }),
});

const validate =
  (translations: Translations) => (formInput: FormInput | null) => {
    const { confirm_email, email } = formInput || defaultValue;
    if (
      !emailsMatch({
        email,
        confirm_email,
      })
    ) {
      return {
        confirm_email: {
          customMessage:
            translations.providersearch.loginModal.createAccount
              .emailErrorNotMatch,
        },
      };
    }

    return true;
  };

export const CreateAccountModal = ({
  onClose,
  setAccountEmail,
}: {
  onClose: () => void;
  setAccountEmail: React.Dispatch<React.SetStateAction<string | undefined>>;
}) => {
  const { loginModal, setLoginModal } = useProviderSearchContext();
  const { isMobile } = useMedia();
  const props = getDialogProps({ loginModal, isMobile });
  const { getDocumentUrl } = useLegalDocuments();
  const theme = useTheme();
  const { isDesktop } = useMedia();
  const translations = useTranslations();
  const [error, setError] = useState<LoginErrorType>();
  const [createAccount, createAccountProgress] = useCreateProviderSearchAccount(
    {
      onCompleted: () => setLoginModal(PROVIDER_SEARCH_SIGNUP_MODAL_SUCCESS),
      onError: (err) => {
        if (
          getApolloNetworkErrorMessage(err)?.includes("account already exists")
        ) {
          setError(SIGNUP_ERROR_TYPE_ALREADY_EXISTS);
          return;
        }
        console.error(`provider search - unhandled account create error`, {
          err,
        });
      },
    },
  );

  return (
    <ProviderSearchDialog onClose={onClose} paperStyle={props.paperStyle}>
      <DialogContentWrapper testId="create-account-modal">
        <SimpleFormRenderProps
          asHtmlForm
          formInputValue={defaultValue}
          modelDefinition={modelDefinition}
          validate={composeValidation(
            validate(translations),
            validateModel(modelDefinition),
          )}
          onSubmit={({ email }: FormInput) => {
            const sanitizedEmail = email.toLowerCase().trim();
            createAccount({ email: sanitizedEmail });
            setAccountEmail(sanitizedEmail);
          }}
        >
          {({ submit }) => {
            return (
              <>
                <DialogTitle textAlign="center" sx={{ paddingTop: 0 }}>
                  {translations.providersearch.loginModal.createAccount.title}
                </DialogTitle>
                <VerticalLayout
                  aligned
                  style={{
                    width: isMobile ? "95%" : dp(328),
                    maxWidth: dp(328),
                    padding: padding(0, 1),
                    overflow: "visible",
                    boxSizing: "border-box",
                  }}
                >
                  <AsteriskExplained />
                  <TextInputField
                    elementName="email"
                    required
                    marginOverride={margin(1, 0, 1)}
                    fullWidth
                    textInputType="email"
                    hasCustomValidation
                    label={
                      translations.providersearch.loginModal.createAccount
                        .emailOnePlaceholder
                    }
                    variant="outlined"
                  />
                  <TextInputField
                    elementName="confirm_email"
                    required
                    marginOverride={margin(1, 0, 2)}
                    fullWidth
                    textInputType="email"
                    hasCustomValidation
                    label={
                      translations.providersearch.loginModal.createAccount
                        .confirmEmailPlaceholder
                    }
                    variant="outlined"
                  />
                  <HorizontalDivider
                    color={theme.palette.neutrals.medium_grey}
                  />
                  <VerticalLayout
                    style={{
                      alignItems: "flex-start",
                      width: "100%",
                      overflow: "visible",
                    }}
                  >
                    <CheckboxInputField
                      label={
                        <CaptionWithLink
                          message={
                            translations.providersearch.loginModal.createAccount
                              .acceptTerms
                          }
                          href={getDocumentUrl(DOCUMENT_TERMS_AND_CONDITIONS)}
                          type="pdf"
                        />
                      }
                      elementName="terms_and_conditions"
                      required
                    />
                    <CheckboxInputField
                      label={
                        <CaptionWithLink
                          message={
                            translations.providersearch.loginModal.createAccount
                              .acceptDPA
                          }
                          href={getDocumentUrl(DOCUMENT_PRIVACY_POLICY)}
                          type="pdf"
                        />
                      }
                      elementName="privacy_policy"
                      required
                    />
                    <Caption
                      as="p"
                      margin={margin(2, 0)}
                      fontSize={FONT_SIZE_12}
                      whiteSpace="normal"
                    >
                      {
                        translations.providersearch.loginModal.createAccount
                          .infoText
                      }
                    </Caption>
                  </VerticalLayout>
                </VerticalLayout>
                <DialogActions
                  sx={{
                    boxSizing: "border-box",
                    flexDirection: "column",
                    maxWidth: dp(328),
                    width: "100%",
                    padding: padding(0, 1),
                  }}
                >
                  <RSButton
                    buttonWrapperStyle={{
                      width: "100%",
                    }}
                    style={{ width: "100%", margin: margin(1, 0.1) }}
                    color="primary"
                    id="sign-up-submit"
                    loading={createAccountProgress}
                    onClick={submit}
                    type="submit"
                    variant="contained"
                  >
                    {
                      translations.providersearch.loginModal.createAccount
                        .submitButton
                    }
                  </RSButton>
                </DialogActions>
                <LoginErrors error={error} />
                <GoToModal
                  goToModal={PROVIDER_SEARCH_LOGIN_MODAL}
                  translation={
                    translations.providersearch.loginModal.createAccount
                      .logInLinkInfo
                  }
                />
              </>
            );
          }}
        </SimpleFormRenderProps>
        {!isDesktop ? (
          <>
            <HorizontalDivider color={theme.palette.neutrals.medium_grey} />
            <ProviderRegistrationInfo aligned />
          </>
        ) : null}
      </DialogContentWrapper>
    </ProviderSearchDialog>
  );
};
