import { ApolloError } from "@apollo/client";
import { DialogActions, DialogTitle, useTheme } from "@mui/material";
import {
  REDIRECT_REASON_GENERIC_ERROR,
  REDIRECT_REASON_LINK_EXPIRED,
  REDIRECT_REASON_NOT_AUTHORIZED,
  REDIRECT_REASON_PARAM,
  RETURN_PARAM,
} from "@recare/core/consts";
import { getApolloNetworkErrorMessage } from "@recare/core/model/utils/errors";
import { RedirectReason } from "@recare/core/types";
import {
  SimpleFormRenderProps,
  convertModelDefinition,
} from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import Translations from "@recare/translations/types";
import { useLoginProviderSearch } from "apollo/hooks/mutations";
import { useProviderSearchContext } from "context/ProviderSearchContext";
import RSButton from "ds/components/RSButton";
import TextInputField from "ds/components/TextInputField";
import { HorizontalLayout, VerticalLayout } from "ds/materials/layouts";
import { dp, margin, padding } from "ds/materials/metrics";
import { Body } from "ds/materials/typography";
import { useMedia } from "dsl/atoms/ResponsiveMedia";
import { AsteriskExplained } from "dsl/molecules/AsteriskExplained";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { getDialogProps } from ".";
import {
  LOGIN_ERROR_TYPE_DOES_NOT_EXIST,
  LOGIN_ERROR_TYPE_NOT_ACTIVE,
  LoginErrorType,
  LoginErrors,
} from "./Error";
import { GoToModal } from "./GoToModule";
import {
  DialogContentWrapper,
  PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS,
  PROVIDER_SEARCH_SIGNUP_MODAL,
  ProviderSearchDialog,
  providerSearchEmailDef,
} from "./shared";

type FormInput = { email: string };

const modelDefinition = convertModelDefinition({
  ...providerSearchEmailDef("email"),
});

const handleLoginError =
  (
    setError: React.Dispatch<React.SetStateAction<LoginErrorType | undefined>>,
  ) =>
  (err: ApolloError) => {
    if (getApolloNetworkErrorMessage(err)?.includes("account - not found")) {
      setError(LOGIN_ERROR_TYPE_DOES_NOT_EXIST);
      return;
    }
    if (
      getApolloNetworkErrorMessage(err)?.includes("error account not active")
    ) {
      setError(LOGIN_ERROR_TYPE_NOT_ACTIVE);
      return;
    }
    console.error(`provider search - unhandled login error`, { err });
  };

const getRedirectReasonErrorText = (
  redirectReason: string,
  translations: Translations,
) => {
  const redirectReasonInt = parseInt(redirectReason) as RedirectReason;
  switch (redirectReasonInt) {
    case REDIRECT_REASON_LINK_EXPIRED:
      return translations.providersearch.expiredLink;
    case REDIRECT_REASON_NOT_AUTHORIZED:
      return translations.providersearch.notLoggedInError;
    case REDIRECT_REASON_GENERIC_ERROR:
      return translations.providersearch.loginModal.loginModal.errorGeneric;
    default:
      console.error(`unable to parse redirect reason: ${redirectReason}`);
      return translations.providersearch.genericErrorMessage;
  }
};

export const LoginModal = ({ onClose }: { onClose: () => void }) => {
  const { loginModal, setLoginModal } = useProviderSearchContext();
  const { isMobile } = useMedia();
  const props = getDialogProps({ loginModal, isMobile });
  const translations = useTranslations();
  const theme = useTheme();
  const [error, setError] = useState<LoginErrorType>();
  const [search, setSearch] = useSearchParams();
  const removeParams = () => {
    search.delete(REDIRECT_REASON_PARAM);
    search.delete(RETURN_PARAM);
    setSearch(search);
  };

  const [login, loginProgress] = useLoginProviderSearch({
    onCompleted: () => {
      setLoginModal(PROVIDER_SEARCH_LOGIN_MODAL_SUCCESS);
      removeParams();
    },
    onError: handleLoginError(setError),
  });

  const redirectReason = search.get(REDIRECT_REASON_PARAM);

  return (
    <ProviderSearchDialog
      onClose={() => {
        removeParams();
        onClose();
      }}
      paperStyle={props.paperStyle}
      // flicker on close icon focus
      // when redirected to login modal
      focusDelay={redirectReason ? 500 : undefined}
    >
      <DialogContentWrapper testId="login-modal">
        <SimpleFormRenderProps
          asHtmlForm
          formInputValue={{ email: "" }}
          modelDefinition={modelDefinition}
          onSubmit={({ email }: FormInput) => {
            login({ email });
          }}
        >
          {({ submit }) => {
            return (
              <>
                <DialogTitle textAlign="center" sx={{ paddingTop: 0 }}>
                  {translations.providersearch.loginModal.loginModal.title}
                </DialogTitle>
                <VerticalLayout
                  aligned
                  style={{
                    boxSizing: "border-box",
                    width: isMobile ? "95%" : dp(328),
                    maxWidth: dp(328),
                    padding: padding(0, 1),
                    overflow: "visible",
                  }}
                >
                  {redirectReason && (
                    <HorizontalLayout
                      padding={padding(1, 0)}
                      margin={margin(0, 0, 2)}
                      width="100%"
                      style={{
                        borderRadius: dp(6),
                        backgroundColor: theme.palette.error.light,
                      }}
                    >
                      <Body
                        as="p"
                        margin={margin(0, 2)}
                        style={{ color: theme.palette.error.dark }}
                      >
                        {getRedirectReasonErrorText(
                          redirectReason,
                          translations,
                        )}
                      </Body>
                    </HorizontalLayout>
                  )}
                  <HorizontalLayout width="100%">
                    <AsteriskExplained customMargin={margin(0, 0, 2)} />
                  </HorizontalLayout>
                  <TextInputField
                    elementName="email"
                    required
                    marginOverride={margin(0, 0, 1)}
                    fullWidth
                    textInputType="email"
                    hasCustomValidation
                    validation={!!error}
                    onKeyDown={() => setError(undefined)}
                    label={
                      translations.providersearch.loginModal.createAccount
                        .emailOnePlaceholder
                    }
                    variant="outlined"
                  />
                </VerticalLayout>
                <DialogActions
                  sx={{
                    boxSizing: "border-box",
                    flexDirection: "column",
                    maxWidth: dp(328),
                    width: "100%",
                    padding: padding(0, 1),
                  }}
                >
                  <RSButton
                    buttonWrapperStyle={{
                      width: "100%",
                    }}
                    style={{
                      width: "100%",
                      margin: margin(1, 0),
                      minWidth: dp(148),
                    }}
                    color="primary"
                    id="login-confirm"
                    loading={loginProgress}
                    onClick={submit}
                    type="submit"
                    variant="contained"
                  >
                    {
                      translations.providersearch.loginModal.createAccount
                        .submitButton
                    }
                  </RSButton>
                  <LoginErrors error={error} />
                  <GoToModal
                    goToModal={PROVIDER_SEARCH_SIGNUP_MODAL}
                    translation={
                      translations.providersearch.loginModal.loginModal
                        .registerLinkInfo
                    }
                  />
                </DialogActions>
              </>
            );
          }}
        </SimpleFormRenderProps>
      </DialogContentWrapper>
    </ProviderSearchDialog>
  );
};
