import {
  OperationVariables,
  WatchQueryFetchPolicy,
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_NOT_STARTED,
  QUERY_PROGRESS_PENDING,
  QUERY_PROGRESS_SUCCEED,
} from "@recare/core/consts";
import { useSafeState } from "@recare/core/hooks";
import { QueryProgress } from "@recare/core/types";
import { useCallback, useEffect } from "react";

export function getFetchPolicy(fetchPolicy: WatchQueryFetchPolicy | undefined) {
  return process.env.NODE_ENV === "test" || (window as any).IN_STORYBOOK
    ? // cache-only not supported by MockedProvider from react-apollo
      undefined
    : fetchPolicy;
}

export function useApolloProgress<
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>({
  data,
  error,
  loading,
}:
  | ReturnType<typeof useQuery<TData, TVariables>>
  | ReturnType<typeof useMutation<TData, TVariables>>[1]) {
  const [progress, setProgress] = useSafeState<QueryProgress>(
    QUERY_PROGRESS_NOT_STARTED,
  );

  const resetProgress = useCallback(
    (delay = 0) =>
      setTimeout(() => setProgress(QUERY_PROGRESS_NOT_STARTED), delay),
    [setProgress],
  );

  useEffect(() => {
    if (loading) setProgress(QUERY_PROGRESS_PENDING);
    if (error) setProgress(QUERY_PROGRESS_FAILED);
    if (data) setProgress(QUERY_PROGRESS_SUCCEED);
  }, [loading, error, data]);

  return { progress, resetProgress };
}
