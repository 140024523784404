import { Theme, useTheme } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import { handleKeyDown } from "@recare/core/model/accessibility/keyboardActions";
import { TourElement } from "@recare/core/types";
import { BadgeContainer, NewBadge } from "ds/components/Badge";
import { Circle } from "ds/components/Circles";
import Tooltip from "ds/components/Tooltip";
import { ACCENT_COLOR, WHITE } from "ds/materials/colors";
import { HorizontalLayout } from "ds/materials/layouts";
import { dp, margin, padding, sizing } from "ds/materials/metrics";
import { Tab as TabText } from "ds/materials/typography";
import {
  Children,
  ComponentProps,
  ReactNode,
  cloneElement,
  forwardRef,
  isValidElement,
} from "react";
import { Link, useLocation } from "react-router-dom";
import styled, { CSSProperties } from "styled-components";

const TabsWrapper = styled.div<{ padding?: CSSProperties["padding"] }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: ${({ padding }) => padding};
`;

export const TabsContainer = styled.div<{
  fit?: boolean;
  height?: CSSProperties["height"];
  minHeight?: CSSProperties["minHeight"];
  minWidth?: CSSProperties["minWidth"];
  padding?: CSSProperties["padding"];
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  width: ${(props) => (props.fit ? "auto" : dp(280))};
  min-width: ${({ minWidth }) => minWidth || dp(72)};
  min-height: ${({ minHeight }) => minHeight ?? dp(48)};
  height: ${({ height }) => (height ? height : dp(48))};
  position: relative;
`;

const TabContainer = styled.div<{
  disabled?: boolean;
  margin?: CSSProperties["margin"];
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1 1 auto;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? null : "pointer")};
  margin: ${({ margin }) => margin ?? null};
  > div {
    display: flex;
    flex: 1 1 auto;
    align-items: stretch;
  }
`;

const TabContentBox = styled.div<{ asRow?: any }>`
  display: flex;
  flex-direction: ${(props) => (props.asRow ? "row" : "column")};
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
`;

export const IndicatorElement = styled.hr<{ color?: CSSProperties["color"] }>`
  height: ${dp(2)};
  background-color: ${({ color }) => color ?? WHITE};
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  margin: ${margin(0)};
  padding: ${padding(0)};
  border: ${dp(0)};
`;

const getColor = ({
  disabled,
  primary,
  selected,
  theme,
}: {
  disabled: boolean | undefined;
  primary: boolean | undefined;
  selected: boolean | undefined;
  theme: Theme;
}) => {
  if (primary && disabled) {
    return theme.palette.grey[600];
  }
  if (primary && selected) {
    return theme.palette.primary.main;
  }
  if (primary) {
    return theme.palette.common.black;
  }
  return undefined;
};

const LinkComponent = forwardRef<
  HTMLAnchorElement,
  ComponentProps<typeof Link>
>((linkProps, ref) => <Link {...linkProps} ref={ref} />);

export function Tab({
  children,
  disabled,
  disableTouchRipple,
  fontSize,
  showNotification,
  showBadge,
  label,
  lineHeight,
  onClick = () => {},
  tabMargin,
  tabPadding,
  minWidth,
  match,
  custom,
  id,
  isSelected,
  testId,
  tooltip,
  tour,
  primary = false,
  uppercase = true,
  url,
  wrapLabel = false,
}: {
  children?: (b: boolean) => ReactNode;
  custom?: boolean;
  disableTouchRipple?: boolean;
  disabled?: boolean;
  fontSize?: CSSProperties["fontSize"];
  id?: string;
  isSelected?: boolean;
  label?: string;
  lineHeight?: CSSProperties["lineHeight"];
  match: string;
  minWidth?: CSSProperties["minWidth"];
  onClick?: () => void;
  primary?: boolean;
  showBadge?: boolean;
  showNotification?: boolean;
  tabMargin?: CSSProperties["margin"];
  tabPadding?: CSSProperties["padding"];
  testId?: string;
  tooltip?: string;
  tour?: TourElement;
  uppercase?: boolean;
  url?: string;
  wrapLabel?: boolean;
}) {
  const location = useLocation();
  const theme = useTheme();

  const isLink = url != null;

  const selected =
    (location?.pathname && location.pathname.search(new RegExp(match)) >= 0) ||
    isSelected ||
    false;
  // needed for ie 11 (My Account Tab)
  if (custom && children) return <>{children(selected)}</>;

  const handleClick = () => (disabled ? null : onClick());

  const baseComponentProps = isLink
    ? { to: url, onClick: handleClick }
    : {
        disableTouchRipple,
        focusRipple: true,
        onClick: handleClick,
        onKeyDown: handleKeyDown({ onConfirm: handleClick }),
      };

  return (
    <Tooltip title={tooltip}>
      <TabContainer margin={tabMargin} className={match}>
        <ButtonBase
          {...tour}
          aria-current={selected ? "page" : undefined}
          component={isLink ? LinkComponent : "div"}
          data-testid={testId}
          disabled={disabled}
          id={id}
          {...baseComponentProps}
          style={{
            width: "100%",
            height: "100%",
            outlineOffset: dp(-1),
            display: "flex",
            flex: `1 1 auto`,
            padding: tabPadding,
            textAlign: "center",
            minWidth,
          }}
        >
          <TabContentBox asRow={children !== undefined}>
            {children?.(selected) || (
              <BadgeContainer>
                <HorizontalLayout aligned>
                  <TabText
                    as="p"
                    selected={selected}
                    fontSize={fontSize}
                    lineHeight={lineHeight}
                    uppercase={uppercase}
                    color={getColor({ disabled, primary, selected, theme })}
                    light={selected}
                    style={{
                      wordBreak: wrapLabel ? "break-word" : undefined,
                      whiteSpace: "normal",
                    }}
                  >
                    {label}
                  </TabText>
                  {showNotification && (
                    <Circle
                      color={ACCENT_COLOR}
                      position="absolute"
                      top={sizing(0)}
                      right={sizing(-2)}
                    />
                  )}
                  {showBadge && <NewBadge marginWrapper={margin(0, 0, 0, 1)} />}
                </HorizontalLayout>
              </BadgeContainer>
            )}
          </TabContentBox>
        </ButtonBase>
        {selected && (
          <IndicatorElement
            color={primary ? theme.palette.primary.main : undefined}
          />
        )}
      </TabContainer>
    </Tooltip>
  );
}

type TabsBaseProps = {
  as?: React.ElementType;
  children: ReactNode;
  containerAs?: React.ElementType;
  containerStyle?: CSSProperties;
  fit?: boolean;
  height?: CSSProperties["height"];
  minHeight?: CSSProperties["minHeight"];
  minWidth?: CSSProperties["minWidth"];
  tabsContainerPadding?: CSSProperties["padding"];
};

type TabsProps = TabsBaseProps &
  (
    | { label: string; labelledBy?: never }
    | { label?: never; labelledBy: string }
    | { label?: never; labelledBy?: never }
  );

export default function Tabs({
  as,
  children,
  containerAs,
  containerStyle,
  fit,
  height,
  label,
  labelledBy,
  minHeight,
  minWidth,
  tabsContainerPadding,
}: TabsProps) {
  return (
    <TabsWrapper
      as={as}
      aria-label={label}
      aria-labelledby={labelledBy}
      padding={tabsContainerPadding}
    >
      <TabsContainer
        as={containerAs}
        fit={fit}
        minWidth={minWidth}
        height={height}
        minHeight={minHeight}
        style={containerStyle}
      >
        {Children.map(children, (child) =>
          isValidElement(child) ? cloneElement(child) : child,
        )}
      </TabsContainer>
    </TabsWrapper>
  );
}
