import { ApolloClient } from "@apollo/client";
import { activateSeald } from "@recare/core/model/utils/featureFlags";
import {
  Account,
  ApolloCacheData,
  AppType,
  EncryptionContext,
  EnvContext,
  SEALD_FLOW_TYPES,
} from "@recare/core/types";
import { useLocale } from "@recare/translations";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useTracking } from "react-tracking";
import { getSealdSDKInstance, importIdentity } from ".";
import { getError } from "../model/utils/errors";
import { CreateGroupInContextProps, createSealdGroup } from "./groups";
import { CreateSessionInContextProps, createSealdSession } from "./sessions";
import { UpdateSealdRegistered } from "./utils";

type SealdContextType =
  | {
      createSealdGroup: CreateSealdGroup;
      createSealdSession: CreateSealdSession;
      importSealdIdentity: ImportSealdIdentity;
      isActive: boolean;
    }
  | undefined;

const SealdContext = React.createContext<SealdContextType>(undefined);

export const useSealdContext = () => useContext(SealdContext);

export type CreateSealdGroup = (
  props: CreateGroupInContextProps,
) => Promise<void>;

export type CreateSealdSession = (
  props: CreateSessionInContextProps,
) => Promise<EncryptionContext | undefined>;

type ImportIdentityInContextProps = {
  account: Account;
  challenge: string | undefined;
  password?: string;
  seald_flow: SEALD_FLOW_TYPES | null;
  token: string;
  twoManSessionId?: string;
  updateAccount: UpdateSealdRegistered;
};

export type ImportSealdIdentity = (
  props: ImportIdentityInContextProps,
) => Promise<true | undefined>;

function useImportIdentityWhenLogged({
  app,
  importSealdIdentity,
  loggedAccount,
  onLogout,
}: {
  app: AppType;
  importSealdIdentity: ImportSealdIdentity;
  loggedAccount: Account | undefined;
  onLogout: () => void;
}) {
  const [restoring, setRestoring] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    console.log("...");
    async function restore() {
      if (await getSealdSDKInstance()) {
        setActive(true);
      } else if (activateSeald(app) && loggedAccount?.id) {
        setRestoring(true);

        try {
          await importSealdIdentity({
            account: loggedAccount,
            challenge: undefined,
            token: "",
            seald_flow: null,
            updateAccount: null,
          });
          setActive(Boolean(await getSealdSDKInstance()));
        } catch (err) {
          const error = getError(err);
          console.error(
            `Seald restore id failed - account id ${loggedAccount.id}: ${error.message}`,
            error,
          );
          onLogout();
        }

        setRestoring(false);
      }
    }

    restore();
  }, [loggedAccount?.id, importSealdIdentity, onLogout]);

  return { restoring, isActive: active };
}

export default function SealdContextProvider({
  apolloClient,
  app,
  children,
  envContext,
  loggedAccount,
  onLogout,
}: {
  apolloClient: ApolloClient<ApolloCacheData>;
  app: AppType;
  children: React.ReactNode;
  envContext: EnvContext;
  loggedAccount: Account | undefined;
  onLogout: () => void;
}) {
  const { trackEvent } = useTracking();
  const localeString = useLocale();

  const importIdentityInContext = useCallback(
    ({
      account,
      challenge,
      password,
      seald_flow,
      token,
      twoManSessionId,
      updateAccount,
    }: ImportIdentityInContextProps) => {
      return importIdentity({
        account,
        seald_flow,
        challenge,
        password,
        token,
        updateAccount,
        twoManSessionId,
        // in context
        envContext,
        trackEvent,
        localeString,
      });
    },
    [envContext],
  );

  const { isActive, restoring } = useImportIdentityWhenLogged({
    loggedAccount,
    importSealdIdentity: importIdentityInContext,
    app,
    onLogout,
  });

  const createGroupInContext = useCallback(
    ({
      createEncryptionContext,
      createSealdAccess,
      entity,
      entityAccounts,
      entityType,
      initiateSealdIdentity,
      loggedAccount,
    }: CreateGroupInContextProps) =>
      createSealdGroup({
        entityType,
        entity,
        entityAccounts,
        initiateSealdIdentity,
        createEncryptionContext,
        createSealdAccess,
        loggedAccount,
        // in context
        envContext,
        trackEvent,
        app,
        apolloClient,
        localeString,
      }),
    [loggedAccount, envContext, app],
  );

  const createSealdSessionInContext = useCallback(
    ({
      checkSealdGroupAccess,
      createEncryptionContext,
      createSealdAccess,
      entity,
      entityType,
      entityUsersDisplayIds,
      loggedGroupDisplayId,
    }: CreateSessionInContextProps) =>
      createSealdSession({
        entityType,
        entity,
        entityUsersDisplayIds,
        createEncryptionContext,
        createSealdAccess,
        checkSealdGroupAccess,
        loggedGroupDisplayId,
        // in context
        loggedAccount,
        envContext,
        app,
        apolloClient,
      }),
    [loggedAccount, envContext, app],
  );

  if (restoring) return null;

  return (
    <SealdContext.Provider
      value={{
        importSealdIdentity: importIdentityInContext,
        createSealdGroup: createGroupInContext,
        createSealdSession: createSealdSessionInContext,
        isActive,
      }}
    >
      {children}
    </SealdContext.Provider>
  );
}
